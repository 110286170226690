
.article-content {
    position: fixed;
    top: 60px;
    bottom: 0;
    width: calc(100% - 230px);
    margin-top: 100px;
    background: #F5F7F6;
    

    .article-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin: 20px;
                background: #ffffff;
            }
        }
    }
    .top-people{
        padding: 20px 0;
    }
    .top-tab {
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px solid #F1F5FF;
        height: 40px;
        padding: 10px 0;

        .el-button {
            margin-right: 20px;
        }
    }

    .main-content {
        margin-top: 20px;
        flex: 1;
        height: 1%;
         background: #fff;
        .main-wrap {
            width: 900px;
            margin: 0 auto;
            box-shadow: 0px 0px 10px #dcdfe6;
            padding: 30px 30px;
            .top-title {
                text-align: center;
                font-size: 20px;
                color: #333333;
            }

            .top-author {
                margin-top: 12px;
                text-align: center;
                font-size: 14px;
                color: #666666;
            }

            .top-content {
                margin-top: 20px;

                ::v-deep p {
                    img {
                        max-width: 900px;
                    }
                }
            }
        }
    }
}
